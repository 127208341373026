var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-tables","fluid":"","tag":"section"}},[_c('v-card',[_c('v-card-title',[_vm._v(" نوع الاسقف "),_c('v-spacer'),_c('v-spacer'),(_vm.permissions.add)?_c('router-link',{attrs:{"to":{ path: '/treatment-settings/bishopTypeSettingForm/'},"color":"blue"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"blue"}},[_vm._v(" إضافة نوع سقف + ")])],1):_vm._e()],1),_c('v-data-table',{attrs:{"loading":_vm.dataLoading,"headers":_vm.headers,"search":_vm.search,"items":_vm.items,"items-per-page":20,"footer-props":{
        'items-per-page-options': [10, 20, 30, 40, 50]
      },"options":_vm.options,"server-items-length":_vm.total,"page-count":_vm.numberOfPages},on:{"update:options":function($event){_vm.options=$event},"fetchAllItems":_vm.fetchAllItems},scopedSlots:_vm._u([(_vm.permissions.update || _vm.permissions.read || _vm.permissions.remove)?{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [(_vm.permissions.update || _vm.permissions.read)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('router-link',{attrs:{"to":'/treatment-settings/bishopTypeSettingForm/' + item.id}},[_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","fab":"","outlined":"","color":"blue"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1)]}}],null,true)},[_vm._v(" تعديل ")]):_vm._e(),(_vm.permissions.remove)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","fab":"","outlined":"","color":"error"},on:{"click":function($event){return _vm.confirmDeleteUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-trash-alt ")])],1)]}}],null,true)},[_vm._v(" حذف ")]):_vm._e()]}}:null,{key:"no-data",fn:function(){return [_c('p',[_vm._v("لا يوجد بيانات")])]},proxy:true}],null,true)})],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.deleteDailog),callback:function ($$v) {_vm.deleteDailog=$$v},expression:"deleteDailog"}},[_c('v-card',{staticClass:"text-center"},[_c('base-material-card',{staticClass:"pt-12",attrs:{"title":"تأكيد حذف","color":"error"}},[_c('v-card-text',{staticClass:"mt-2"},[_vm._v(" هل انت متأكد من حذف "+_vm._s(_vm.userDetails.name)+" ؟ ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green","outlined":"","loading":_vm.loading,"disabled":_vm.disabled},on:{"click":function($event){return _vm.deleteUser(_vm.userDetails)}}},[_vm._v(" حذف ")]),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.deleteDailog = false}}},[_vm._v(" اغلاق ")])],1)],1)],1)],1),_c('v-snackbar',{attrs:{"color":"success","shaped":"","bottom":"","left":"","timeout":_vm.timeout},model:{value:(_vm.successSnackbar),callback:function ($$v) {_vm.successSnackbar=$$v},expression:"successSnackbar"}},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]),_c('v-snackbar',{attrs:{"color":"red","shaped":"","bottom":"","left":"","timeout":_vm.timeout},model:{value:(_vm.errorSnackbar),callback:function ($$v) {_vm.errorSnackbar=$$v},expression:"errorSnackbar"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }